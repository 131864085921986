import React from 'react';
import Body from './Body/Body';

const News_4e0508 = () => {
  return (
    <div>
      <Body />
    </div>
  );
};

export default News_4e0508;
